import Artifea from "../../assets/images/Artifea-Logo.jpg";
import Markhor from "../../assets/images/Markhor-Logo.jpg";
import Upwork from "../../assets/images/upwork-tile.svg";
import arrowleftcircle from "../../assets/images/arrowleftcircle.svg";

export const testimonials = [
    {
        message: `I had the pleasure of working with Ammar Haider for our Company, MARKHOR STRATEGIES (a sourcing and supply chain management company).

        Ammar Haider is a professional graphic designer who designed all our templates, business cards, LinkedIn pages, and company logo. He has a keen eye for detail, a creative flair, and a customer-centric approach.
        
        He always delivered high-quality work on time and within budget. Ammar Haider is an asset to any organisation that needs graphic design services. I highly recommend him for any future opportunities.
        `,
        by: "Markhor Strategies",
        company: 'Markhor Strategies',
        imageSrc: Markhor,
        iconSrc: arrowleftcircle,
    },
    {
        message: `I highly recommend Insane Studios, as a reliable and skilled graphic designer. I have been using his services for over 4 years now, and I have consistently been impressed by his creativity and attention to detail.

        Ammar Haider is a true professional and always strives to understand his client's vision and goals. He is highly knowledgeable about various design software and techniques, which enables him to create visually stunning designs that perfectly communicate his clients' messages.
                
        I highly recommend Ammar Haider. He has a passion for design and consistently produces exceptional work. I have no doubt that he will exceed your expectations and provide you with a product that you will be proud to display.
        `,
        by: "Shahid Imran - CEO Artifea",
        company: 'Artifea',
        imageSrc: Artifea,
        iconSrc: arrowleftcircle,
    },
  
  
   
];
