import { useScrollContext } from "../App"
import React, { useEffect } from "react";
import img from "../assets/images/INSANE.jpg"
import "aos/dist/aos.css"; // Import AOS CSS
import AOS from "aos"; // Import AOS library

const GetToKnowUs = () => {

    const { scrollTo } = useScrollContext()
    useEffect(() => {
        AOS.init(); // Initialize AOS
    
        // You can set a delay and duration for the animation (optional)
        AOS.init({ duration: 1000, delay: 100 });
    
        // Add the data-aos attribute to trigger the "fade-up" animation
      }, []);

    return (
        
        <div className="get-to-know-us" id="about-me"  >
            <div className="first-div">
                <h1 className="gettoknowheading" data-aos="fade-right">
                    Get To Know <br/> About Us Closer</h1>
                <div className="get-to-know-us-text " data-aos="fade-right">
                    Founded in 2023, Insane Studios is a visionary leader in the realm of digital services. Our mission is to deliver nothing short of excellence in the digital landscape. Comprising a team of dedicated and industrious professionals, we have become synonymous with top-tier workmanship in Pakistan.
                    <br/> With a keen understanding of the evolving digital landscape and a passion for staying at the forefront of technological advancements, we stand ready to tackle even the most intricate digital challenges. Insane Studios is your trusted partner for unlocking the full potential of your digital endeavors, and we take pride in being a beacon of excellence in the digital service sector.
                </div>
            </div>
            <div>
                <img data-aos="fade-left"
                    src={img} alt="Your browser does not support this" 
                />
            </div>
        </div>
    )
}

export default GetToKnowUs
